<template>
	<div
		class=""
	>
		<div
			class=""
		>

			<div class="justify-space-between-m">채
				<button
					@click="pdt_div = 'business'"
					class="btn flex-1 btn-s-m"
					:class="pdt_div == 'business' ? 'btn-fill' : 'btn-outline'"
				>기업 상품</button>
				<button
					@click="pdt_div = ''"
					class="btn btn-fill flex-1  btn-s-m"
					:class="pdt_div == '' ? 'btn-fill' : 'btn-outline'"
				>일반 상품</button>
			</div>
			<template
				v-if="true"
			>
				<ul class="hide-pc top-line mt-10">
					<template
						v-for="(item, item_index) in item_list"
					>
					<li
						v-for="(product, product_index) in item.items"
						:key="'product_m_' + item_index + '_' + product_index"
						class="justify-space-between items-center gap-10 under-line pb-10 mt-10"
					>
						<div>
							<div class="square-64 box ">
								<img
									:src="product.pdt_img" alt="main1"
									@error="$bus.$emit('onErrorImage', $event)"
									class="object-cover width-100"
								/>
							</div>
						</div>
						<div class="">
							<div>{{ product.pdt_name }}</div>
							<div
								v-for="(option, index) in product.options"
								:key="'odt_' + index"
								class=" under-line-dashed mt-10"
							>
								<div
									class="text-left"
									@click="setCartOptionSelect(option, option.odt_uid)"
								>
										<v-icon
											v-if="option.is_not_select"
											class="size-px-16"
										>mdi-checkbox-blank-outline</v-icon>
										<v-icon
											v-else
											class="color-green size-px-16"
										>mdi mdi-checkbox-marked</v-icon>
										<span class="color-gray"> {{ option.odt }}</span>
								</div>

								<div
									class=" justify-space-between mt-10"
								>
									<span
										class="flex-1  mr-10"
									>{{ option.odt_price | makeComma }} 원</span>

									<div
										v-if="option.is_sold"
									>품절</div>
									<span
										v-else
										class="flex-1 flex-row justify-space-between box-pdt-cnt"
									>
										<button
											@click="setCnt(option, 'down')"
											class="flex-1 mdi mdi-minus size-px-14"
										></button>
										<input
											v-model="option.odt_cnt"
											type="number"
											name="pdt_cnt"
											class="flex-1 bg-gray-light"
											readonly
										/>
										<button
											@click="setCnt(option, 'up')"
											class="flex-1 mdi mdi-plus size-px-14"
										></button>
									</span>
								</div>
								<div
									class="mt-10 under-line-dashed text-right color-blue"
								>
									<span>{{ option.odt_cnt * option.odt_price | makeComma }} 원</span>
								</div>
							</div>
						</div>
					</li>
					</template>
				</ul>
			<table class="table mt-10 hide-m">
				<colgroup>
					<col width="80px" />
					<col width="60px" />
					<col width="auto" />
					<col width="auto" />
					<col width="120px" />
					<col width="120px" />
					<col width="120px" />
					<col width="120px" />
				</colgroup>
				<thead>
					<tr>
						<th>구분</th>
						<th colspan="2">상품명</th>
						<th>옵션</th>
						<th>가격</th>
						<th>수량</th>
						<th>합계</th>
						<th>삭제</th>
					</tr>
				</thead>
				<tbody>
					<template
						v-for="(item, item_index) in item_list"
					>
						<tr
							v-for="(product, product_index) in item.items"
							:key="'product_' + item_index + '_' + product_index"
							class=""
						>
							<td>{{ product.pdt_div_name }}</td>
							<td>
								<div>
									<img
										:src="product.pdt_img" alt="main1"
										@error="$bus.$emit('onErrorImage', $event)"
										class="object-cover width-100"
									/>
								</div>
							</td>
							<td class="td-left">
								{{ product.pdt_name }}
							</td>
							<td>
								<div
									v-for="(option, index) in product.options"
									:key="'odt_' + index"
									class=" pa-10 under-line-dashed"
								>
									<div
										class="justify-space-between"
									>
										<button
											class="size-px-13"
											@click="setCartOptionSelect(option, option.odt_uid)"
										>
											<v-icon
												v-if="option.is_not_select"
												class="size-px-16"
											>mdi-checkbox-blank-outline</v-icon>
											<v-icon
												v-else
												class="color-green size-px-16"
											>mdi mdi-checkbox-marked</v-icon>
											<span class="ml-5 color-gray"> {{ option.odt }}</span>
										</button>
									</div>
								</div>
							</td>

							<td>
								<div
									v-for="(option, index) in product.options"
									:key="'odt_' + index"
									class=" pa-10 under-line-dashed"
								>
									<div
										class=" justify-space-between"
									>
										<span
											class="flex-1 color-blue"
										>{{ option.odt_price | makeComma }} 원</span>
									</div>
								</div>
							</td>

							<td>
								<div
									v-for="(option, index) in product.options"
									:key="'odt_' + index"
									class=" pa-10 under-line-dashed"
								>
									<div
										class=" justify-space-between"
									>
										<div
											v-if="option.is_sold"
										>품절</div>
										<span
											v-else
											class="flex-1 flex-row justify-space-between box-pdt-cnt"
										>
											<button
												@click="setCnt(option, 'down')"
												class="flex-1 mdi mdi-minus size-px-14"
											></button>
											<input
												v-model="option.odt_cnt"
												type="number"
												name="pdt_cnt"
												class="flex-2 bg-gray-light"
												readonly
											/>
											<button
												@click="setCnt(option, 'up')"
												class="flex-1 mdi mdi-plus size-px-14"
											></button>
										</span>
									</div>
								</div>
							</td>
							<td>

								<div
									v-for="(option, index) in product.options"
									:key="'odt_cnt_' + index"
									class=" pa-10 under-line-dashed"
								>
									<span>{{ option.odt_cnt * option.odt_price | makeComma }} 원</span>
								</div>
							</td>
							<td>
								<div
									v-for="(option, index) in product.options"
									:key="'odt_' + index"
									class=" pa-10 under-line-dashed"
								>
									<button
										@click="removeCart(option.cart_index, option.odt_uid)"
										class="btn-default"
									>삭제</button>
								</div>
							</td>
						</tr>
						<tr
							v-if="false"
							:key="'item' + item.uid"
						>
							<td colspan="8">
								<div
									class="pa-10 under-line-dashed text-right"
								>
									<span>배송비 <span class="size-px-11">{{ item.company.delivery }}</span></span>
									<span style="width: 80px">{{ Number(item.company.delivery_price) + Number(item.company.pdt_delivery_price) | makeComma }} 원</span>
								</div>
								<div
									class="pa-10 text-right"
								>
									<span>합계</span>
									<span class="color-blue" style="width: 80px">{{ item.company.total_price | makeComma }} 원</span>
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			</template>
			<Empty
				v-else
				class="mt-30"
			></Empty>
		</div>

		<div
			class="mt-auto flex-column position-relative"
		>
			<div>
				<div
					class="pa-10 text-right under-line-dashed"
				>
					<span>배송비 합계</span>
					<span style="width: 80px">{{ total_delivery_price | makeComma }} 원</span>
				</div>
				<div
					class="pa-10 text-right under-line"
				>
					<span
						class="font-weight-bold color-black mr"
					>총 상품가 </span>
					<span
						class="order-price font-weight-bold color-blue"
						style="width: 80px"
					>{{ total_price | makeComma }} 원</span>
				</div>
			</div>
		</div>
		<div
			class="mt-50 text-center justify-space-between-m mb-50-m"
		>
			<button
				class="btn btn-fill mr-10 flex-1 btn-s-m"
				@click="toOrder"
				:disabled="is_disabled"
			>주문하기</button>

			<button
				class="btn btn-outline flex-1 btn-s-m"
				@click="goBack"
			>쇼핑 계속하기</button>
		</div>
	</div>
</template>

<script>
	import Empty from "@/view/Layout/Empty";
	export default{
		name: 'Cart'
		, components: {Empty}
		, props: ['user', 'shop_info', 'cart_info']
		, data: function(){
			return {
				program: {
					name: '장바구니'
					, top: false
					, title: true
					, bottom: true
					, top_line: true
					, from: 'Index'
				}
				//,total_delivery_price: 0
				, is_all: false
				, pdt_div: this.$route.params.pdt_div ? this.$route.params.pdt_div : ''
			}
		}
		,computed: {
			total_price: function(){
				let price = 0;

				if(this.cart_items.length > 0) {
					for (let i = 0; i < this.cart_items.length; i++) {
						if(this.pdt_div == 'business'){
							if(this.cart_items[i].pdt_div != this.pdt_div){
								continue
							}
						}else{
							if(this.cart_items[i].pdt_div == 'business'){
								continue
							}
						}
						if (!this.cart_items[i].is_not_select) {
							price += (Number(this.cart_items[i].pdt_price) + Number(this.cart_items[i].op_price)) * this.cart_items[i].op_cnt
							//price += Number(this.cart_items[i].pdt_delivery)
						}
					}
				}

				return price;
			}
			,total_delivery_price: function(){
				let price = 0

				if(Object.keys(this.item_list).length > 0){
					for(const [key, val] of Object.entries(this.item_list)){
						console.log(key)
						price += Number(val.company.delivery_price)
						//price += Number(val.company.pdt_delivery_price)
					}
				}

				return price
			}
			,is_cart_select: function(){
				let result = false

				if(Object.keys(this.item_list).length > 0){
					for(const [key, val] of Object.entries(this.cart_items)){
						console.log('is_select_cart : ' + key)
						if(!val.is_not_select){
							result = true
							break
						}
					}
				}
				return result
			}
			,item_list: function(){
				let items = {}

				for(const [key ,val] of Object.entries(this.cart_items)){

					let company = items[val.seller_id]

					if(!company){
						company = {
							company: {
								total_price: 0
								,pdt_delivery_price: 0
							}
							,items: {}
						}
					}

					items[val.seller_id] = company

					items[val.seller_id]['company']['seller_id'] = val.seller_id
					items[val.seller_id]['company']['seller_name'] = val.shop_name
					if(!val.is_not_select) {
						items[val.seller_id]['company']['total_price'] += ((Number(val.pdt_price) + Number(val.op_price)) * val.op_cnt)
					}
					items[val.seller_id]['company']['delivery_type'] = val.delivery_type
					items[val.seller_id]['company']['delivery_price'] = val.delivery_price

					// items[val.seller_id]['company']['pdt_delivery_price'] += (Number(val.pdt_delivery) * val.op_cnt)

					if (val.delivery_type == '0') {
						items[val.seller_id]['company']['delivery_price'] = val.delivery_type
						items[val.seller_id]['company']['delivery'] = ''
					} else {
						if (val.free_price > 0) {
							items[val.seller_id]['company']['delivery'] = val.free_price + ' 이상 구매시 무료'
							if (val.free_price <= items[val.seller_id]['company']['total_price'] || items[val.seller_id]['company']['total_price'] == 0) {
								items[val.seller_id]['company']['delivery_price'] = 0
							}
						}
					}

					let product = items[val.seller_id]['items'][val.pdt_uid]

					if(!product || product === undefined){
						product = {
							pdt_uid: val.pdt_uid
							,pdt_img: val.pdt_img1 ? val.pdt_img1 : ''
							,pdt_name: val.pdt_name
							,pdt_price: val.pdt_price
							,pdt_delivery: val.pdt_delivery
							,options: {}
						}
					}

					product.pdt_div_name = val.pdt_div_name

					items[val.seller_id]['items'][val.pdt_uid] = product

					let option = items[val.seller_id]['items'][val.pdt_uid]['options'][val.uid]
					if(!option || option === undefined){
						option = {
							odt_uid: val.uid
							,odt: val.op_name
							,odt_cnt: val.op_cnt
							,odt_price: Number(val.pdt_price) + Number(val.op_price)
							,cart_index: key
							,is_not_select: val.is_not_select
						}
					}

					if(val.is_sold == 1 || (val.is_sold == 2 && val.pdt_stock < 1)){
						option.is_sold = true
						option.is_not_select = true

						this.setCartOptionSelect(option)
					}


					if(this.pdt_div == 'business'){
						if(val.pdt_div != this.pdt_div){
							option.is_not_select = true
							continue
						}
					}else{
						if(val.pdt_div == 'business'){
							option.is_not_select = true
							continue
						}
					}

					items[val.seller_id]['items'][val.pdt_uid]['options'][val.uid] = option

				}

				return items
			}
			, is_disabled: function(){
				let t = true
				this.cart_items.filter((item) => {
					if(!item.is_not_select){
						t = false
					}
				})
				return t
			}
			, cart_items: function(){
				let t = []
				this.cart_info.filter((item) => {
					if(this.pdt_div == 'business'){
						if(item.pdt_div == this.pdt_div){
							t.push(item)
						}
					}else{
						if(item.pdt_div != 'business'){
							t.push(item)
						}
					}

				})
				return t
			}
		}
		,methods: {
			removeCart: async function(cart_index, odt_uid){
				if(confirm('해당 상품을 삭제하시겠습니까?')){
					this.$bus.$emit('on', true)
					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'order/postDeleteCartItem'
							,data: {
								odt_uid: odt_uid
							}
						})

						if(result.success){
							this.$bus.$emit('notify', { type: 'success', message: result.message })
							this.$emit('getCartList')
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch(e){
						console.log(e)
					}finally {
						this.$bus.$emit('on', false)
					}
				}
			}

			,setCnt: async function(odt, type){
				let cnt = odt.odt_cnt
				if(cnt == ''){
					cnt = 1
				}

				let add_price = odt.odt_price
				if(type == 'up'){
					if(cnt >= 99){
						cnt = 99
					}else{
						cnt = Number(cnt) + 1
					}
				}else{
					if(cnt <= 1){
						cnt = 1
						return false
					}else{
						cnt = Number(cnt) - 1
						add_price *= -1
					}
				}

				let max = this.shop_info.payment_limit

				if(Number(this.total_price) + Number(add_price) >= Number(max)){
					// this.$bus.$emit('notify', { type: 'error', message: '1회 구매 한도를 초과하였습니다.'})
					// cnt--
				}

				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'order/postOdtCnt'
						,data: {
							c_uid: odt.odt_uid
							,op_cnt: cnt
						}
					})

					if(!result.success){
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$emit('getCartList')
				}
			}
			,toOrder: function(){
				if(!this.is_cart_select){
					this.$bus.$emit('notify', { type: 'error', message: '선택된 상품이 없습니다.'})
				}else {

					for(const [key, val] of Object.entries(this.item_list)){
						for(const [key2, val2] of Object.entries(val.items)){
							for(const [key3, val3] of Object.entries(val2.options)) {
								this.cart_info.filter((cart) => {
									if (val3.odt_uid == cart.uid) {
										if (!val.is_not_select) {
											cart.key = key + key2 + key3
											this.$set(cart, 'is_use', true)
										}
									}
								})
							}
						}
					}
					if(this.shop_info.is_payment_limit == 1){
						if(this.total_price >= this.shop_info.payment_limit){
							this.$bus.$emit('notify', { type: 'error', message: '1회 구매 한도를 초과하였습니다.'})
						}else{
							this.$router.push({name: 'OrderForm'})
						}
					}else{
						this.$router.push({name: 'OrderForm'})
					}
				}
			}
			,setCartOptionSelect: function(option){

				let val = !this.cart_items[option.cart_index].is_not_select ? true : false

				if(option.is_sold){
					val = true
				}

				this.$set(this.cart_items[option.cart_index], 'is_not_select', val)
			}
			, goBack: function(){
				this.$bus.$emit('goBack')
			}
		}
		,created: function(){
			this.$bus.$emit('onLoad', this.program)
			this.$emit('getCartList')
		}
	}
</script>

<style>

.pdt-cnt {
	padding: 10px;
}
.box-pdt-cnt {
}

.box-pdt-cnt > button {
	border: 1px solid #ddd;
	width: 100%;
	font-size: 20px;
}
.box-pdt-cnt > input {
	border: none;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 0px 5px;
	width: 100%;
	text-align: center;
}

.odt-img {
	margin-right: 10px
}
.odt-img img {width: 100% !important;}

.btn-remove-cart{
	border: 1px solid #ddd; font-size: 12px; padding: 0px 3px
}
</style>